import { default as animationtestVYMbWUgAgdMeta } from "C:/Users/ashle/OneDrive/Desktop/ash/pages/animationtest.vue?macro=true";
import { default as brandingsi8JMEkGCdMeta } from "C:/Users/ashle/OneDrive/Desktop/ash/pages/branding.vue?macro=true";
import { default as contactitYv58xEs7Meta } from "C:/Users/ashle/OneDrive/Desktop/ash/pages/contact.vue?macro=true";
import { default as indexvxs4ztZSFuMeta } from "C:/Users/ashle/OneDrive/Desktop/ash/pages/index.vue?macro=true";
import { default as landingvIgzkWTNvjMeta } from "C:/Users/ashle/OneDrive/Desktop/ash/pages/landing.vue?macro=true";
import { default as originaljokoGDtNQEMeta } from "C:/Users/ashle/OneDrive/Desktop/ash/pages/original.vue?macro=true";
import { default as privacy_45policyzsSZCrhiU1Meta } from "C:/Users/ashle/OneDrive/Desktop/ash/pages/privacy-policy.vue?macro=true";
import { default as terms1EsZ23WF4MMeta } from "C:/Users/ashle/OneDrive/Desktop/ash/pages/terms.vue?macro=true";
export default [
  {
    name: "animationtest",
    path: "/animationtest",
    component: () => import("C:/Users/ashle/OneDrive/Desktop/ash/pages/animationtest.vue")
  },
  {
    name: "branding",
    path: "/branding",
    component: () => import("C:/Users/ashle/OneDrive/Desktop/ash/pages/branding.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("C:/Users/ashle/OneDrive/Desktop/ash/pages/contact.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("C:/Users/ashle/OneDrive/Desktop/ash/pages/index.vue")
  },
  {
    name: "landing",
    path: "/landing",
    component: () => import("C:/Users/ashle/OneDrive/Desktop/ash/pages/landing.vue")
  },
  {
    name: "original",
    path: "/original",
    component: () => import("C:/Users/ashle/OneDrive/Desktop/ash/pages/original.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("C:/Users/ashle/OneDrive/Desktop/ash/pages/privacy-policy.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("C:/Users/ashle/OneDrive/Desktop/ash/pages/terms.vue")
  }
]